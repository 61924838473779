.container {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  --bs-gutter-x: 0;

  .tagTitulo {
    font-size: 3.3333333333333335vw;
    font-weight: 100;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .btnPasseMouse {
    font-size: 1.0416666666666667vw;
    margin-top: -1.251303441084463vh;
    margin-left: 0.3125vw;

    span {
      font-family: Helvetica;
      font-size: 1.0416666666666667vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-right: 0.5208333333333334vw;
    }
  }

  .conteudoFlechasDesktop {
    margin-left: 30%;
    // margin-top: -8.5%;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
  }

  .divNomeDesigner {
    position: relative;
  }

  .nomeDesigner {
    font-family: Helvetica;
    font-size: 1.0416666666666667vw;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);

    span {
      margin-left: 5px;
      position: absolute;
      top: 11%;
    }
  }

  @media screen and (min-width: 1081px) {
    .containerTitulo {
      position: fixed;
      left: 4%;
      top: 44%;

      .tagTitulo {
        img {
          width: 300px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    top: 8.5%;

    .containerTitulo {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      text-align: center;

      .tagTitulo {
        margin: 5% 0;

        img {
          width: 140px;
        }
      }
    }

    .conteudoComFlechasMobile {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      max-height: 84vh;
      overflow: hidden;
    }

    .nomeDesignerMobile {
      position: absolute;
      right: 5%;
      top: 34%;
      z-index: 2;
      font-family: Helvetica;
      font-size: 4.5vw;
      font-weight: 500;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 1);
      // span {
      //   margin-left: 5px;
      // }
    }
  }
}