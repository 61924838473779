.whatsapp {
    position: fixed;
    z-index: 1056;
    right: 1%;
    bottom: 1%;
    cursor: pointer;

    img {
        width: 45px;
        height: 45px;
    }
}