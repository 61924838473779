.container {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba($color: #000000, $alpha: 0.5);
    opacity: 0.1;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }

  .content {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    padding-top: 13%;
    padding-left: 20%;
    height: 100vh;
    max-width: 40%;
    width: 80%;
    background-image: url("../images/Menu-01.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-in-out;
    background-position: left center;
  }

  .iconeMenu {
    position: absolute;
    top: 3.5%;
    right: 15%;
    transition: transform 0.2s ease-in-out;
  }

  .rodape {
    position: absolute;
    bottom: 8%;
    // z-index: 999;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.2s ease-in-out;
    color: var(--f-cor-primaria);
    font-size: 0.77rem;
    margin-left: 4rem;
    opacity: 0.7;

    img {
      max-width: 200px;
      margin-bottom: 8%;
    }
  }

  .left {
    left: 0;
    transform: translateX(-102%);
  }

  .right {
    right: 0;
    transform: translateX(102%);
  }

  // &::after {
  //   content: "";
  //   position: fixed;
  //   border-top: 50vh solid transparent;
  //   border-bottom: 50vh solid transparent;
  //   top: 0;
  //   bottom: 0;
  //   right: 420px;
  //   width: 120px;
  //   height: 0;
  //   border-right: 38vh solid rgb(20, 20, 20);
  //   z-index: 999;
  //   // opacity: 0;
  //   // background-color: var(--bg-secundario);
  //   transition: transform 0.2s ease-in-out;
  //   transform: translateX(300%);
  // }

  &[data-is-open="true"] {
    &::before {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }

    &::after {
      // opacity: 0.91;
      visibility: visible;
      pointer-events: visible;
      transform: translateX(0);
    }

    .content {
      transform: translateX(0);
    }
    .rodape {
      transform: translateX(0);
    }
  }
  @media screen and (max-width: 1080px) {
    .iconeMenu {
      position: absolute;
      top: 2.5%;
      right: 1.44rem;
      transition: transform 0.2s ease-in-out;
    }

    .content {
      padding-top: 15%;
      background-image: url("../images/Menu-02.png");
      max-width: 75%;
      height: 80vh;
    }

    .rodape {
      position: absolute;
      bottom: 15%;
      color: var(--f-cor-primaria);
      font-size: 0.6rem;
      margin-left: 0.1rem;
      opacity: 0.7;

      img {
        max-width: 180px;
        margin-bottom: 5%;
      }
    }
  }
}
