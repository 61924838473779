.container {
  .defContainer {
    min-width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
  }
  &[data-estilo="preto"] {
    &::before {
      content: " ";
      position: absolute;
      z-index: 0; // Ajustado para ficar por baixo do defContainer
      top: 0;
      height: 100%;
      width: 100vh; // Certificar que cobre toda a área
      background-image: url("../images/imagem_fundo_branco.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 60px 0;
      background-color: var(--bg-preto);
    }
    min-height: 100vh;
    z-index: -2;
  }
  @media screen and (min-width: 1024px) {
    .conteudo {
      width: 90%;
    }
    &[data-estilo="transparent"] {
      .conteudo {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .conteudo {
      width: 100%;
    }
  }
}
