.container {
  --clip-path-imagem: polygon(15% 0%, 100% 0, 100% 100%, 15% 100%, 0% 50%);
  --posicaoImagem: 0 20%;
  --text-body: white;
  --detail-high-contrast: rgb(54, 49, 61);
  --detail-medium-contrast: rgba(255, 255, 255, 0.27);
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: transparent;
  --bs-gutter-x: 0;

  .btnVoltar {
    position: absolute;
    left: 8%;
    top: 48%;
    z-index: 2;
    color: white;
    font-size: 1.0416666666666667vw;
    font-weight: 700;
    letter-spacing: 0.1em;
    cursor: pointer;

    span {
      font-family: Helvetica;
      text-transform: uppercase;
      margin-left: 0.5208333333333334vw;
      position: absolute;
      top: 14%;
    }
  }

  .conteudoFlechasDesktop {
    margin-left: 5%;
    height: 100vh;
    width: 95%;
    min-height: 100%;
    box-sizing: border-box;

    .imagemDesigner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
      clip-path: var(--clip-path-imagem);

      img {
        object-fit: cover;
        object-position: var(--posicaoImagem);
        height: 100%;
        width: 100%;
      }

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background: rgba(0, 0, 0, 0.4);
        /* Inicialmente transparente */
      }
    }
  }

  .conteudoDesigner {
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: -10%;
    //   top: -3%;
    //   width: 113%;
    //   height: 115%;
    //   z-index: 0;
    //   filter: blur(25px);
    //   background-color: transparent;
    //   background: linear-gradient(
    //     to right,
    //     rgba(0, 0, 0, 0.4),
    //     rgba(0, 0, 0, 1)
    //   );
    // }
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    left: 35%;
    top: 1%;
    width: 65%;
    height: 100%;

    .conteudoDesignerInterno {
      position: relative;
      z-index: 2;
      background-color: transparent;
      color: #fff;
      padding-right: 5%;

      h1 {
        font-family: Helvetica Neue;
        text-transform: uppercase;
        font-size: 2.5vw;
        font-weight: 800;
        letter-spacing: 0.18em;
        text-align: center;
        margin-bottom: 3%;
      }

      h4 {
        font-family: Helvetica Neue;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.18em;
      }

      img {
        width: 100%;
        height: 100%;
        min-width: unset;
      }
    }
  }

  @media screen and (min-width: 1081px) {
    .containerTitulo {
      position: fixed;
      left: 4%;
      top: 44%;
    }
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    top: 8.5%;

    .conteudoDesignerMobileInterno {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        object-position: var(--posicaoImagem);
        width: 100vw;
        height: 60vh;
        z-index: -1;
      }
    }

    .linhaMobile {
      position: relative;
      width: 100%;
      background: rgba(0, 0, 0, 0.7);
      height: 10px;

      &::after {
        position: absolute;
        border-bottom: 1.5px solid rgba(255, 255, 255, 0.4) !important;
        top: 0;
        left: 5%;
        content: "";
        width: 90%;
      }
    }

    .divDesigner {
      position: relative;
      color: white;
      display: flex;
      flex-direction: column;
      margin-top: -10%;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        top: -2.5vh;
        left: -5;
        height: 101%;
        width: 120%;
        z-index: -1;
        filter: blur(10px);
        background: linear-gradient(to top,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.87));
      }

      .divDesinerInterna {
        width: 90%;

        h1 {
          text-transform: uppercase;
          margin-bottom: 40px;
          font-family: Helvetica Neue;
          font-weight: 800;
          text-decoration: underline;
          text-underline-offset: 1.4vh;
        }

        h4 {
          font-family: Helvetica Neue;
          font-weight: 400;
          font-size: 1.8vh;
        }
      }

      .cartasProduto {

        // margin-top: 15%;
        img {
          width: 90vw;
          height: 100%;
          z-index: 1;
        }
      }
    }
  }
}