.embla {
  position: relative;
  display: flex;
  width: 100%;
  height: 50.2rem;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
.embla:before,
.embla:after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  display: block;
  height: calc(50% - 32px / 2);
  z-index: 1;
  pointer-events: none;
}
.embla:before {
  top: -7.8px;
  border-bottom: 0.5px solid rgba(var(--text-high-contrast-rgb-value), 0.3);
  background: linear-gradient(
    to top,
    rgba(var(--background-site-rgb-value), 0.65) 0%,
    rgba(var(--background-site-rgb-value), 1) 100%
  );
}
.embla:after {
  bottom: -7.8px;
  border-top: 0.5px solid rgba(var(--text-high-contrast-rgb-value), 0.3);
  background: linear-gradient(
    to bottom,
    rgba(var(--background-site-rgb-value), 0.65) 0%,
    rgba(var(--background-site-rgb-value), 1) 100%
  );
}
.embla__ios-picker {
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 100%;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
}
.embla__ios-picker__scene {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  touch-action: pan-x;
}
.embla__ios-picker__viewport {
  height: 32px;
  width: 100%;
  perspective: 1000px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.embla__ios-picker__container {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  will-change: transform;
}
.embla__ios-picker__slide {
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.embla__ios-picker__container a {
  text-decoration: none;
  color: unset;
}
.embla__ios-picker__label {
  font-weight: 700;
  transform: translateX(-55px);
  pointer-events: none;
}

.imgFecharLista{
  right: 1%;
  top: 5%;
  position: absolute;
  z-index: 2;
}
