.container {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-family: Helvetica Neue;
}

.conteudo {
    width: 100%;
}

.imgAreaExterna {
    width: 100%;
}

.textosQuemSomos {
    h1 {
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    h3 {
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        background-image: url("../../images/fundo_vktum.png");
    }

    .conteudo {
        margin-top: 10%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .textosQuemSomos {
        margin-left: 30px;

        p {
            margin-top: 20px;
            margin-left: 25px;
        }
    }
}

@media screen and (max-width: 1024px) {

    .conteudo {
        margin-top: calc(var(--tamanhoTotal) *1);
    }

    .textosQuemSomos {
        margin-top: 20px;

        h1 {
            margin-bottom: 20px;
        }
    }

    .linhaMobile {
        position: relative;
        width: 100%;
        height: 30px;

        &::after {
            position: absolute;
            border-bottom: 1.5px solid rgba(255, 255, 255, 0.4) !important;
            top: 0;
            content: "";
            width: 100%;
        }
    }

}