@media screen and (min-width: 1081px) {
  .slideDesktop {
    visibility: visible;
    height: 100%;
    margin-top: 12px;
  }

  .slideMobile {
    visibility: hidden;
    height: 0;
    width: 0;
    line-height: 0;
  }
}

@media screen and (max-width: 1080px) {
  .slideMobile {
    visibility: visible;
    margin-top: 14px;
  }

  .slideDesktop {
    visibility: hidden;
    height: 0;
    width: 0;
    line-height: 0;
  }
}

.slideMobile {
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--f-cinza) !important;
  opacity: 0.6;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  margin-bottom: 14px;
}

.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
  // justify-content: center;
  height: 100%;
}

.embla__slide {
  flex: 0 0 10%;
  align-content: center;
  text-align: center;
  display: flex;
  margin: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  cursor: pointer;
  height: 100%;
  align-items: center;
  white-space: nowrap !important;
}

.selecionado {
  color: #0090ff;
  font-weight: 900 !important;
  letter-spacing: 0.14em;
  font-size: 24px;
}

.normal {
  color: var(--f-cinza);
  letter-spacing: 0.18em;
  font-weight: 400;
  opacity: 0.4;
  font-size: 24px;
  align-items: center;
}

.traco {
  margin: 7px 5px;
  opacity: 0.4;
  color: var(--f-cinza);
}