.cardProduto {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

@media screen and (max-width: 1024px) {
    .cardProduto {
        aspect-ratio: 1 / 1.15;
        object-fit: cover;
    }
}