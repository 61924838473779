.embla_flecha_vertical {
  margin: auto;
  --slide-height: 43rem;
  --slide-spacing: 1rem;
  --slide-size: calc(100% / 4);
  --slide-curva-imagem: 30%;
  --intensidade-efeito-preto: 0.7;
  --clip-path-normal: polygon(50% 0%,
      /* Top tip */
      100% var(--slide-curva-imagem),
      /* Top right */
      100% 50%,
      /* Inner right */
      100% 100%,
      /* Bottom right */
      0% 100%,
      /* Bottom left */
      0% 50%,
      /* Inner left */
      0% var(--slide-curva-imagem)
      /* Top left */
    );
  --clip-path-ultimo: polygon(50% 0%,
      /* Top tip */
      100% var(--slide-curva-imagem),
      /* Top right */
      100% 50%,
      /* Inner right */
      100% 100%,
      /* Bottom right */
      0% 100%,
      /* Bottom left */
      0% 50%,
      /* Inner left */
      0% var(--slide-curva-imagem)
      /* Top left */
    );
  --clip-path-primeiro: polygon(50% 0%,
      /* Top tip */
      100% var(--slide-curva-imagem),
      /* Top right */
      100% 50%,
      /* Inner right */
      100% 100%,
      /* Bottom right */
      0% 100%,
      /* Bottom left */
      0% 50%,
      /* Inner left */
      0% var(--slide-curva-imagem)
      /* Top left */
    );
  --margem-top-primeiro: -40%;
}

.embla_flecha_vertical__viewport {
  overflow: hidden;
}

.embla_flecha_vertical__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-x pinch-zoom;
  margin-top: calc(var(--slide-spacing) * -1);
  height: calc(var(--slide-spacing) + var(--slide-height));
  flex-direction: column;
}

.embla_flecha_vertical__slide {
  position: relative;
  min-width: 0;
  flex: 0 0 var(--slide-size);
  min-height: 0;
  padding-top: 22vh;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;

    divEspecial {
      visibility: visible;
    }
  }

  divEspecial {
    position: absolute;
    z-index: 2;
    visibility: hidden;
  }
}

.embla_flecha_vertical__slide:last-of-type {
  padding-top: 0;
}

.embla_flecha_vertical__slide__number {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40.041710114702816vh;
  margin-top: -50%;
  clip-path: var(--clip-path-normal);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-normal);
  }
}

.embla_flecha_vertical__slide__number-primeiro {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40.041710114702816vh;
  margin-top: var(--margem-top-primeiro);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-primeiro);
  }
}

.embla_flecha_vertical__slide__number-ultimo {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -10%;
  height: 40.041710114702816vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-ultimo);
  }
}

.embla_flecha_vertical__slide__number_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40.041710114702816vh;
  margin-top: -50%;
  clip-path: var(--clip-path-normal);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-normal);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, var(--intensidade-efeito-preto));
    clip-path: var(--clip-path-normal);
  }
}

.embla_flecha_vertical__slide__number-primeiro_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40.041710114702816vh;
  margin-top: var(--margem-top-primeiro);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-primeiro);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, var(--intensidade-efeito-preto));
    clip-path: var(--clip-path-primeiro);
  }
}

.embla_flecha_vertical__slide__number-ultimo_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -10%;
  height: 40.041710114702816vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-ultimo);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, var(--intensidade-efeito-preto));
    clip-path: var(--clip-path-ultimo);
  }
}