.container {
  position: relative;
  .btnVoltar {
    position: fixed;
    height: 23px;
    left: 5%;
    top: 48%;
    cursor: pointer;
    color: black;
    font-size: 1.0416666666666667vw;
    font-weight: 700;
    letter-spacing: 0.1em;
    span {
      text-transform: uppercase;
      margin-left: 10px;
    }
  }
  .conteudo {
    position: absolute;
    left: 24%;
    margin-top: 3%;
    width: 79%;
    .imgPrincipal {
      width: 33.33%;
      min-width: 33.33%;
      box-sizing: border-box;
      display: flex;
      img {
        width: 100%;
        max-height: 467px;
      }
      video {
        width: 100%;
        max-height: 467px;
      }
    }
    .dadosProduto {
      width: 66.67%;
      min-width: 66.67%;
      padding-left: 45px;
      box-sizing: border-box; /* Inclui padding e bordas na largura total */
    }

    /* Exemplo para garantir que os filhos também respeitem a largura */
    .dadosProduto > * {
      width: 100%;
      box-sizing: border-box;
    }
    h2 {
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.18em;
      font-size: 2.5vw;
    }
    h3 {
      font-size: 1.25vw;
      font-weight: 400;
      letter-spacing: 0.18em;
      text-transform: uppercase;
      color: rgba(51, 51, 51, 1);
    }
    span {
      font-size: 1.0416666666666667vw;
      font-weight: 300;
      letter-spacing: 0.18em;
      margin-top: 46px;
    }
    .listaImagens {
      margin-top: 19px;
    }
    .btnOrcamento {
      margin-top: 51px;
      cursor: pointer;
      color: black;
      font-size: 1.0416666666666667vw;
      font-weight: 700;
      letter-spacing: 0.1em;      
      margin-bottom: 10px;
      span {
        text-transform: uppercase;
        margin-left: 10px;
        font-weight: 700;
        letter-spacing: 0.1em;
      }
    }
    .cardOrcamento {
      margin-top: 33px;
      background: rgba(51, 51, 51, 1);
      border-radius: 20px !important;
      .corpoFormOrcamento {
        margin: 0 0.3em;
      }
      span {
        font-size: 1.0416666666666667vw;
        font-weight: 800;
        letter-spacing: 0.1em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
      .formOrcamento {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        input[type="text"] {
          background: rgba(230, 230, 230, 1);
          font-size: 1.0416666666666667vw;
          font-weight: 300;
          letter-spacing: 0.1em;
        }

        input[type="text"]:focus {
          border: 1px solid #323232;
          box-shadow: none;
        }
        input[type="text"]::placeholder {
          color: #4d4d4d !important;
          font-size: 1.0416666666666667vw;
          font-weight: 300;
          letter-spacing: 0.1em;
          text-align: left;
        }
        textarea {
          background: rgba(230, 230, 230, 1);
          border-radius: 10px !important;
          font-size: 1.0416666666666667vw;
          font-weight: 300;
          letter-spacing: 0.1em;
        }
        textarea:focus {
          border: 1px solid #323232;
          box-shadow: none;
        }
        textarea::placeholder {
          color: #4d4d4d !important;
          font-size: 1.0416666666666667vw;
          font-weight: 300;
          letter-spacing: 0.1em;
          text-align: left;
        }
        button {
          width: min-content;
          padding: 0.7em 2.9em;
          border-radius: 8px !important;
          align-self: flex-end;
          font-family: Helvetica Neue;
          font-size: 0.78125vw;
          font-weight: 800;
          letter-spacing: 0.1em;
          color: black;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    .conteudo {
      position: relative;
      left: 0;
      margin-top: 3%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5em;
      .imgPrincipal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        img {
          max-height: 300px;
        }
        video {
          max-height: 300px;
        }
      }
      .dadosProduto {
        width: 100%;
        min-width: 100%;
        padding-left: 0;
        margin-top: 2em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .listaImagens {
        margin: 2.8em 0;
      }
      h2 {
        letter-spacing: 0.12em;
        font-size: 2.1em;
        margin-left: calc(var(--bs-gutter-x) * 1);
      }
      h3 {
        font-size: 1.07em;
        letter-spacing: 0.12em;
        margin-left: calc(var(--bs-gutter-x) * 1);
      }
      span {
        font-size: 0.98em;
        letter-spacing: 0.12em;
        margin-top: 1.2em;
        padding-right: calc(var(--bs-gutter-x) * 1.5);
        margin-left: calc(var(--bs-gutter-x) * 1.5);
      }
      .containerOrcamento {
        padding-right: 0;
        margin-left: calc(var(--bs-gutter-x) * 0.2);
      }
      .cardOrcamento {
        margin-top: 2.8em;
        background: rgba(51, 51, 51, 1);
        border-radius: 1.7em !important;
        .corpoFormOrcamento {
          margin: 0 0;
        }
        span {
          font-size: 1.4em;
          font-weight: 800;
          letter-spacing: 0.1em;
          text-align: left;
          color: rgba(255, 255, 255, 1);
          margin-left: 0;
          padding-right: 0;
        }
        .formOrcamento {
          input[type="text"] {
            font-size: 0.9em;
            letter-spacing: 0.05em;
            height: 3em;
          }
          input[type="text"]::placeholder {
            font-size: 0.9em;
            letter-spacing: 0.05em;
          }
          textarea {
            background: rgba(230, 230, 230, 1);
            border-radius: 8px !important;
            font-size: 0.9em;
            letter-spacing: 0.05em;
          }
          textarea::placeholder {
            font-size: 0.9em;
            letter-spacing: 0.05em;
          }
          button {
            padding: 0.3em 1.9em;
            border-radius: 0.35em !important;
            font-size: 0.9em;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
  @media screen and (min-width: 2000) {
    .container {
      .btnVoltar {
        font-size: 25px;
      }
      .conteudo {
        h2 {
          font-size: 48px;
        }
        h3 {
          font-size: 24px;
        }
        span {
          font-size: 20px;
        }
        .btnOrcamento {
          font-size: 20px;
        }
        .cardOrcamento {
          span {
            font-size: 20px;
          }
          .formOrcamento {
            input[type="text"] {
              font-size: 20px;
            }
            input[type="text"]::placeholder {
              font-size: 20px;
            }
            textarea {
              font-size: 20px;
            }
            textarea::placeholder {
              font-size: 20px;
            }
            button {
              font-size: 15px;
            }
          }
        }
      }      
    }
  }
}