.container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  --bs-gutter-x: 0;
  .btnVoltar {
    font-size: 1.0416666666666667vw;
    margin-top: 4.251303441084463vh;
    margin-left: 1.5125vw;
    cursor: pointer;
    span {
      font-family: Helvetica;
      font-size: 1.0416666666666667vw;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-left: 0.5208333333333334vw;
    }
  }
  .conteudoFlechasDesktop {
    margin-left: 18%;
    margin-top: -8.5%;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
  }
  .divNomeDesigner {
    position: relative;
  }
  .nomeDesigner {
    font-family: Helvetica;
    font-size: 1.0416666666666667vw;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    span {
      margin-left: 5px;
      position: absolute;
      top: 11%;
    }
  }
  @media screen and (min-width: 1081px) {
    .containerTitulo {
      position: fixed;
      left: 4%;
      top: 44%;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    .containerTitulo {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      .tagTitulo {
        margin: 5% 0;
        text-transform: uppercase;
      }
    }
    .conteudoComFlechasMobile {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      max-height: 84vh;
      overflow: hidden;
    }
    .nomeDesignerMobile {
      position: absolute;
      right: 5%;
      top: 34%;
      z-index: 2;
      font-family: Helvetica;
      font-size: 4.5vw;
      font-weight: 500;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 1);
      // span {
      //   margin-left: 5px;
      // }
    }
  }
}
