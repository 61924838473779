.embla_flecha {
  max-width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 50%;
  --slide-spacing-lg: 2rem;
  --slide-size-lg: calc(100% / 4);
  --slide-curva: 50%;
  --slide-margem-direita-primeiro: -50%;
  --slide-margem-direita: -55%;
  --intensidade-efeito-preto: 0.7;
  --clip-path-primeiro: polygon(
    var(--slide-curva) 0%,
    /* Top left */ 100% 0,
    /* Top right */ 100% 100%,
    /* Bottom right */ var(--slide-curva) 100%,
    /* Bottom left */ 0% 50% /* Tip of the arrow */
  );
  --clip-path-normal: polygon(
    40% 0%,
    /* Top left */ 100% 0,
    /* Top right */ 100% 100%,
    /* Bottom right */ 40% 100%,
    /* Bottom left */ 0% 50% /* Tip of the arrow */
  );
  --clip-path-ultimo: polygon(
    var(--slide-curva) 0%,
    /* Top left */ 100% 0,
    /* Top right */ 100% 100%,
    /* Bottom right */ var(--slide-curva) 100%,
    /* Bottom left */ 0% 50% /* Tip of the arrow */
  );
}

.embla_flecha__viewport {
  overflow: hidden;
  position: relative;
}

.embla_flecha__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

@media (min-width: 750px) {
  .embla_flecha__container {
    margin-left: calc(var(--slide-spacing-sm) * -1);
  }
}

@media (min-width: 1200px) {
  .embla_flecha__container {
    margin-left: calc(var(--slide-spacing-lg) * -1);
  }
}

.embla_flecha__slide {
  position: relative;
  min-width: 0;
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
  cursor: pointer;
  &:hover {
    divEspecial {
      visibility: visible;
    }
  }
  divEspecial {
    position: absolute;
    z-index: 2;
    visibility: hidden;
    left: 5%;
    top: 48.5%;
    width: 100%;
  }
}

.embla_flecha__slide_hover {
  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
}

.embla_flecha__slide_preto {
  transition: background 0.3s ease; /* Adicionei a transição para o background */
  &:hover {
    background: rgba(0, 0, 0, 0.5); /* Modificação para escurecimento */
    z-index: 2;
    divEspecial {
      visibility: visible;
    }
  }
  divEspecial {
    position: absolute;
    z-index: 2;
    visibility: hidden;
    left: 5%;
    top: 48%;
    width: 100%;
  }
}

@media (min-width: 750px) {
  .embla_flecha__slide {
    flex: 0 0 var(--slide-size-sm);
    padding-left: var(--slide-spacing-sm);
  }
}

@media (min-width: 1200px) {
  .embla_flecha__slide {
    flex: 0 0 var(--slide-size-lg);
    padding-left: var(--slide-spacing-lg);
  }
}

.embla_flecha__slide__number {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: -35%;
  margin-right: var(--slide-margem-direita);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-normal);
  }
}

.embla_flecha__slide__number-primeiro {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: 0;
  margin-right: var(--slide-margem-direita-primeiro);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-primeiro);
  }
}

.embla_flecha__slide__number-ultimo {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: -45%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-ultimo);
  }
}
.embla_flecha__slide__number-primeiro_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: 0;
  margin-right: var(--slide-margem-direita-primeiro);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-primeiro);
  }
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Inicialmente transparente */
    transition: background 0.3s ease; /* Adicionado a transição para o background */
    clip-path: var(--clip-path-primeiro);
  }
  &:hover::after {
    background: rgba(
      0,
      0,
      0,
      var(--intensidade-efeito-preto)
    ); // Escurecimento no hover
  }
}

.embla_flecha__slide__number-ultimo_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: -45%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-ultimo);
  }
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Inicialmente transparente */
    transition: background 0.3s ease; /* Adicionado a transição para o background */
    clip-path: var(--clip-path-ultimo);
  }
  &:hover::after {
    background: rgba(
      0,
      0,
      0,
      var(--intensidade-efeito-preto)
    ); // Escurecimento no hover
  }
}

.embla_flecha__slide__number_preto {
  position: relative;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: -35%;
  margin-right: var(--slide-margem-direita);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: var(--clip-path-normal);
  }
  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Inicialmente transparente */
    transition: background 0.3s ease; /* Adicionado a transição para o background */
    clip-path: var(--clip-path-normal);
  }
  &:hover::after {
    background: rgba(
      0,
      0,
      0,
      var(--intensidade-efeito-preto)
    ); // Escurecimento no hover
  }
}
