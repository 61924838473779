.headerContainer {
  --cor-mobile: black;
  width: 100%;
  // margin-bottom: 20px;
  display: grid;
  align-items: center;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: var(--fundo-header);

  &[data-estilo="preto"] {
    background-color: transparent;

    .titulo {
      background-color: var(--fundo-header);
      width: 54%;
      height: 100%;
    }

    .fundoBranco {
      background-color: var(--fundo-header);
      height: 100%;
      width: 100%;
      justify-content: end;
      margin-left: 30%;
    }
  }

  &[data-estilo="transparent"] {
    --fundo-header: transparent;
    position: relative;
  }

  &[data-estilo="pretoTransparente"] {
    --fundo-header: transparent;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
    --cor-mobile: white;
    position: relative;
  }

  .actionsMobile {
    color: var(--cor-mobile);
  }

  .menu {
    position: absolute;

    nav {
      grid-area: nav;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: none;

      li {
        margin-right: 40px;

        a {
          font-size: 1.5rem;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 3;
          letter-spacing: normal;
          color: var(--f-cor-primaria) !important;
          text-transform: uppercase;
          text-decoration: none;
          opacity: 1;

          &::before {
            content: "\f105";
            opacity: 0;
            font-family: FontAwesome;
            font-size: 1.9rem;
            margin-right: 1.65rem;
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  header {
    display: grid;
    grid-template-areas: "logo titulo actions";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    height: 150px;
    width: 90%;
    margin: auto;
    padding: 0 10px;
    // border-bottom: 1px solid #d1d1d1;

    .menuIcon {
      display: none;
    }

    .titulo {
      grid-area: titulo;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;
    }

    .action {
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
      padding: 0;
      position: relative;

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      @media screen and (min-width: 1081px) {
        img {
          width: 20px;
          height: 20px;
        }
      }

      .counter {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--sage);
        width: 18px;
        height: 18px;
        border-radius: 100px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (min-width: 1081px) {
      grid-template-areas: "logo titulo actions";
      grid-template-columns: 1fr 1fr 1fr;

      .titulo {
        display: flex;
        justify-content: center;
      }

      .actionsMobile {
        visibility: hidden;
        width: 0%;
        color: var(--cor-mobile);
      }

      .actionsDesktop {
        visibility: visible;
      }
    }

    display: block; // Overriding the grid layout for small screens
    position: relative;

    .logoContainer {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);

      img {
        width: 9.010416666666666vw; // Ajuste conforme necessário
      }
    }

    .actions {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      display: flex;
      justify-content: flex-end;
    }

    .titulo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      img {
        width: 300px;
      }

      a {
        font-weight: 900;
        text-transform: uppercase;
        font-size: 1.4583333333333333vw;
        color: black;
        letter-spacing: 0.1em;
        text-decoration: none;
      }
    }

    @media screen and (max-width: 1080px) {
      display: block;
      position: relative;
      width: 100%;
      min-height: 100px;
      margin-bottom: 12%;

      .logoContainer {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);

        img {
          width: 150px;
        }
      }

      .actions {
        position: absolute;
        top: 37%;
        right: 1.2rem;
        display: flex;
        width: 10%;
      }

      .titulo {
        margin-top: 13%;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        a {
          font-weight: 400;
          text-transform: uppercase;
          font-size: 1.3rem;
          margin-bottom: 10px;
        }

        img {
          width: 140px;
        }

        .actionsMobile {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 20px 0;
          align-self: flex-start;
          width: 100%;
        }
      }

      .input-text-search {
        height: 30px !important;
      }

      .actionsMobile {
        visibility: visible;
      }

      .actionsDesktop {
        visibility: hidden;
        width: 0%;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .menu {
      nav {
        margin-top: 15%;

        li {
          margin-right: 0;

          a {
            font-size: 1rem;
            font-weight: 300;
            font-style: normal;
            line-height: 4.3;

            &::before {
              font-size: 0;
              margin-right: 0;
              content: " ";
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {

    &[data-estilo="transparent"],
    &[data-estilo="pretoTransparente"] {
      header {
        height: var(--tamanhoHeader);
        min-height: 0;
        margin-bottom: 0;
        margin-top: var(--margemTopHeader);

        .titulo {
          margin-top: 0;
        }

        .actions {
          margin-top: -5px;
        }
      }
    }
  }
}